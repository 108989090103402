<template>
    <div v-if="board" class="board-container">
        <table class="board-view">
            <tr>
                <th colspan="2">{{ board.subject }}</th>
            </tr>
            <tr>
                <td colspan="2">
                    <v-row class="row--small">
                        <v-col cols="auto">
                            <span>{{$t('common.작성자')}} : {{ board.writer.name }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.작성일')}} : {{ board.createdAt.toDate() }}</span>
                        </v-col>
                        <v-col cols="auto">
                            <span>{{$t('common.조회수')}} : {{ board.viewCount }}</span>
                        </v-col>
                    </v-row>
                </td>
            </tr>
            <tr>
                <td colspan="2" class="content_box">
                    <div v-html="board.content"></div>
                </td>
            </tr>
        </table>
        <!-- <table v-show="false" class="board-view-navi">
            <tr>
                <th width="20%">이전글</th>
                <td><a href="">이전 게시글 제목입니다.</a></td>
            </tr>
            <tr>
                <th width="20%">다음글</th>
                <td><a href="">다음 게시글 제목입니다.</a></td>
            </tr>
        </table> -->

        <!-- <div class="mt-20 mt-md-30">
            <div v-show="false" class="v-btn--group justify-end">
                <v-btn large color="primary">수정</v-btn>
                <v-btn large outlined color="primary">삭제</v-btn>
                <v-btn large outlined color="primary" class="grey-2--text">답글</v-btn>
            </div>
            <div class="v-btn--group justify-end">
                <v-btn @click="$router.go(-1)" large color="primary">{{$t("common.list")}}</v-btn>
            </div>
        </div> -->

        <div class="mt-20 mt-md-30">
            <div class="v-btn--group justify-end">
                <v-btn @click="$router.go(-1)" large color="primary">{{$t("common.list")}}</v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import api from "@/api"
export default {
    components: {
    },
    props: {
        
    },
    data() {
        return {
            board: null
        }
    },
    mounted() {
        console.log(this.$route.params, "ed3ew");
        this.init()
    },  
    methods: {
        async init() {
            try{
                var { board } = await api.v1.boards.get({ _id: this.$route.params._board });
                if(board.upload) board.upload = await api.getResource(board.upload);
                this.board = board;
                console.log(this.board);
            }
            catch(error){
                console.error(error);
                alert(error.response ? error.response.data.message : error.message);
            }
        }
    }
};
</script>

<style scoped>
.board-view tr td.content_box { max-width: calc(100vw - 24px); word-break: break-all;}
</style>
